import { RsfConfigFactory } from '@rsf/rsf-angular-base/dist/config.service';
import { Environment } from 'src/environments/environment';
import ENV from 'src/environments/env';

export class ApiConstant {
  private static apis = {
    getPolicies: '/ext/vn-online-payment-web/assets/data/data.json',
    payment: '/ext/vn-payment-bff-service/v1/payments/search',
    initPayment: '/ext/vn-payment-bff-service/v1/payment/gateway',
    printReceipt:'/ext/vn-payment-bff-service/v1/payment/order/pdf',
    transactionStatus: '/ext/vn-payment-bff-service/v1/payment/order',//v1/payment/status'
    getTranInfo: '/ext/vn-payment-bff-service/v1/payment/receipt',
    transStatusUpdate: '/ext/vn-payment-bff-service/v1/payment/status',
    getMaintenance: '/ext/vn-payment-bff-service/v1/system/status',
    getSessionDetailCWS: '/ext/vn-payment-bff-service/v1/ses-map',
    updatePayment: '/ext/vn-payment-bff-service/v1/payment/update/status',
    generateQR: '/ext/vn-payment-bff-service/v1/sacombank/generate_qr',
    //getConfig: ENV.env =='non-prod'?'/ext/vn-online-payment-web/config': '/config'
  };
  public static getApiUrl = (propName: string) => {
    const environment: Environment = RsfConfigFactory.getEnv() as Environment;
    const api = ApiConstant.apis[propName as keyof typeof ApiConstant.apis];
    return `${environment.apiUrl}${api}`;
  };
}
